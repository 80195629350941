// extracted by mini-css-extract-plugin
export var productDiscoverySession__card = "dv_Cm";
export var productDiscoverySession__cardFour = "dv_Cr";
export var productDiscoverySession__cardOne = "dv_Cn";
export var productDiscoverySession__cardThree = "dv_Cq";
export var productDiscoverySession__cardTwo = "dv_Cp";
export var productDiscoverySession__cardWrapper = "dv_Cl";
export var productDiscoverySession__description = "dv_Cv";
export var productDiscoverySession__header = "dv_Ck";
export var productDiscoverySession__image = "dv_Cw";
export var productDiscoverySession__info = "dv_Cs";
export var productDiscoverySession__title = "dv_Ct";