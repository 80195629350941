// extracted by mini-css-extract-plugin
export var productDiscoverTestimonialSection = "B_fn";
export var productDiscoveryAchievementsSection = "B_fB";
export var productDiscoveryBenefitsSection = "B_fm";
export var productDiscoveryCaseSection = "B_fv";
export var productDiscoveryClientSection = "B_fC";
export var productDiscoveryIndustriesSection = "B_fl";
export var productDiscoveryOverviewSection = "B_fs";
export var productDiscoveryProcessSection = "B_fr";
export var productDiscoveryProjLogoSection = "B_fz";
export var productDiscoveryResultsSection = "B_fw";
export var productDiscoveryRolesSection = "B_ft";
export var productDiscoveryServicesSection = "B_fk";
export var productDiscoveryStagesSection = "B_fp";
export var productDiscoverySuccessStoriesSection = "B_fx";
export var productDiscoveryWhyChooseSection = "B_fy";
export var productDiscroveryImportanceSection = "B_fq";